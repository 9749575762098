import './Outils.css'
import { openNewTabWithLink } from "../utils/helperFunc";
import {Grid} from "@material-ui/core";


export const Outils = (props: any) => {
    if (Object.keys(props.outils).length === 0) return null
    else
        return (<div>
            {props.outils.map((outil: any) => {
                return (
                    <Grid container id="outilsGrid">
                        <Grid item xs={3}>
                            <div><img src={outil.url_logo} width="70" height="70" alt="vignetteIcon" /></div>
                        </Grid>
                        <Grid>
                            <div id="outilsBox" key={outil.nom} >
                                <strong id="outilsTitle">{outil.nom}</strong>
                                <p id="outilsLink" onClick={() => openNewTabWithLink(outil.url_site_web)}>Accéder &gt;</p>
                            </div>
                        </Grid>
                    </Grid>
                )
            }
            )}
        </div>);
}
