import { BrowserRouter, Routes, Route } from 'react-router-dom';
import { DashBoardPage } from './composants/pages/dashboard/DashBoardPage';
import { GestionPage } from './composants/pages/gestion/GestionPage';
import { LoginPage } from './composants/pages/login/LoginPage';

export const App = () => {
  return (
    <div className="App">
      <BrowserRouter>
        <Routes>
            <Route path="/" element={<LoginPage/>} />
            <Route path="/dashboard" element={<DashBoardPage/>} />
            <Route path="/gestion" element={<GestionPage/>} />
        </Routes>
      </BrowserRouter>
    </div>
  );
}