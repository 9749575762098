import { Button } from "@material-ui/core";
import { useEffect } from "react";
import { isIE } from "react-device-detect";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { isObjectEmpty, logOut, securityCheck } from "../../utils/helperFunc";
import { Gestion } from "./Gestion";

/** GestionPage ('/gestion'): gestion de habilitation page*/
export const GestionPage = () => {
    if (isIE) {
        return (
            <div>
                Portail Bleu n'est pas compatible avec Internet Explorer.
                Merci de bien vouloir vous connecter avec l'un des navigateurs suivants : Microsoft Edge, Google Chrome, Mozilla Firefox.
            </div>
        )
    }
    const user = JSON.parse(localStorage.getItem('user') || '{}');
    const rolesRef = JSON.parse(localStorage.getItem('rolesRef') || '[]');
    const navigate = useNavigate();
    useEffect(() => {
        securityCheck(navigate);
    }, [])
    const location: any = useLocation();

    if (!isObjectEmpty(user) && user.correspondant_informatique)
        return (
            <div>
                <Gestion user={user} rolesRef={rolesRef} />
            </div>
        )
    else if (isObjectEmpty(user) && !location.state && user.correspondant_informatique)
        return (
            <div>
                <Gestion user={location.state.user} rolesRef={location.state.rolesRef} />
            </div>
        )
    else if (!user.correspondant_informatique) return <div>Not allowed</div>
    else return <div>Can't connect to the server please go back to <Button onClick={() => logOut(navigate)}>login page</Button></div>
}
