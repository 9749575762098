import './Communication.css'
import {Divider, Grid} from "@material-ui/core";
import {useStyles} from "../utils/defaultStyles";
import axios from "axios";
import {back_end_url} from "../../app/constants";
import {useEffect, useState} from "react";


export const Communication = (props: any) => {
    const classes = useStyles();
    const parse = require('html-react-parser');
    const [message, setMessage] = useState(null);

    useEffect(() => {
        axios.get(`${back_end_url}/communication`, { withCredentials: true }).then((response) => {
            if (response.data.message) setMessage(response.data.message);
        });
    }, [])

    if (!message)
        return (
            <Divider className={classes.divider} />
        );
    return (
        <>
            <Divider className={classes.dividerCommunication} />
            <Grid container>
                <Grid item xs={12}>
                    <Grid container className={classes.communicationZone}>
                        <Grid item>
                            <div className={classes.communicationInnerZone}>
                                <div className={classes.communicationWarning}><p><img src="../static/logo-info.png" width="30" height="30" alt="vignetteIcon" /></p></div>
                                <p className={classes.communicationMessage}>{parse(message)}</p>
                            </div>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </>
    );
}
