import { Button } from "@material-ui/core";
import { useEffect } from "react";
import { isIE } from "react-device-detect";
import { useLocation, useNavigate } from "react-router-dom";
import { isObjectEmpty, logOut, securityCheck } from "../../utils/helperFunc";
import { DashBoard } from "./DashBoard";

/** DashBoardPage ('/dashboard'): main page*/
export const DashBoardPage = () => {

    if (isIE) {
        return (
            <div>
                <p> Portail Bleu n'est pas compatible avec Internet Explorer.
                    Merci de bien vouloir vous connecter avec l'un des navigateurs suivants : Microsoft Edge, Google Chrome, Mozilla Firefox. </p>
            </div>
        )
    }

    const user = JSON.parse(localStorage.getItem('user') || '{}');
    const rolesRef = JSON.parse(localStorage.getItem('rolesRef') || '[]');
    const f2000 = JSON.parse(localStorage.getItem('f2000') || '[]');
    const outils = JSON.parse(localStorage.getItem('outils') || '[]');
    const navigate = useNavigate();
    const location: any = useLocation();

    useEffect(() => {
        securityCheck(navigate);
    }, [])

    if (!isObjectEmpty(user))
        return (
            <div>
                <DashBoard user={user} rolesRef={rolesRef}
                    f2000={f2000} outils={outils}
                />
            </div>
        )
    else if (!location.state) return <div>Can't connect to the server please go back to <Button onClick={()=> logOut(navigate)}>login page</Button></div>
    else return (
        <div>
            <DashBoard user={location.state.user} rolesRef={location.state.rolesRef}
                f2000={location.state.f2000} outils={location.state.outils}
            />
        </div>
    )
}
