import React from "react";
import { Container, Grid, Typography } from "@material-ui/core";
import { useNavigate } from "react-router-dom";
import { useStyles } from "../../utils/defaultStyles";
import {isObjectEmpty, logOut, openNewTabWithLink} from "../../utils/helperFunc";
import { Actualite } from "../../actualite/Actualite";
import { CgNomBox } from "../../boxes/cgNomBox/CgNomBox";
import { NameBox } from "../../boxes/nameBox/NameBox";
import { MenuDropDown } from "../../boxes/menuDropDown/MenuDropDown";
import { Habilitation } from "../../boxes/habilitation/Habilitation";
import {Communication} from "../../communication/Communication";

type gestionProps = {
    user: any,
    rolesRef: any,
    actualite: any,
}

export const Gestion = (props: gestionProps) => {
    const classes = useStyles();
    const navigate = useNavigate();

    const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(null);
    const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };

    if ((!isObjectEmpty(props.user)) && !props.user.activated)
        return (<div>Votre compte est désactivé, vous ne pouvez pas vous connecter à Portail Bleu. Veuillez contacter votre administrateur local.<br />
            <img src="../static/logout.png" width="30" height="30" onClick={() => logOut(navigate)} alt="logOut" /></div>);
    else
        return (
            <div className={classes.pageView}>
                <div className={classes.appView}>
                    <div className={classes.navigationBar} >
                        <div className={classes.title}>
                            <div className={classes.logo}> <img src="../static/LogoPictosEtFonds/Header-LogoFFF.png" width="45" height="58" alt="Logo FFF" /></div>
                            <div className={classes.line}> <img src="../static/line.png" height="20" alt="line" /> </div>
                            <p className={classes.titleName} onClick={() => navigate('/dashboard')}> Portail<strong>Bleu</strong> </p>
                        </div>
                        <div className={classes.account}>
                            <Typography className={classes.accountName} variant="button" onClick={handleClick}>
                                <NameBox nom={props.user.nom} prenom={props.user.prenom} includeNom={true} />
                            </Typography>
                            <MenuDropDown anchorEl={anchorEl} handleClose={handleClose} user={props.user} rolesRef={props.rolesRef} />
                            {/* <div className={classes.questionMark}><img src="../static/LogoPictosEtFonds/Header-PictoAide.png" width="30" height="30" alt="whoisit" /></div> */}
                            <div className={classes.logOut}><img src="../static/LogoPictosEtFonds/Header-PictoDeconnexion.png" width="30" height="30" onClick={() => logOut(navigate)} alt="logOut" /></div>
                        </div>
                    </div>
                    <main>
                        <div>
                            <Container className={classes.container}>
                                <Grid container className={classes.details}>
                                    <Grid item>
                                        <p className={classes.who}><span className={classes.whoBonjour}>Bonjour</span> <span className={classes.nom}><NameBox nom={props.user.nom} prenom={props.user.prenom} includeNom={false} /></span> </p>
                                    </Grid>
                                    <Grid item>
                                        <CgNomBox cg_nom={props.user.cg_nom} />
                                    </Grid>
                                </Grid>
                                <Communication />
                                <Grid container spacing={5} className={classes.ContentCol}>
                                    <Grid item xs={9} className={classes.ColG}>
                                        <Grid container>
                                            <Habilitation user={props.user} rolesRef={props.rolesRef} />
                                        </Grid>
                                    </Grid>
                                    <Grid item xs={3} className={classes.ColD}>
                                        <Grid container className={classes.Actualite}>
                                            <Grid item xs={12} className={classes.blocTit}>
                                                <p className={classes.vignetteZoneTitle} > Fil d'actualité </p>
                                                <div className={classes.underLine}> <img src="../static/rectangle.png" alt="underline" /></div>
                                            </Grid>
                                            <Grid item xs={12}>
                                                <Actualite />
                                            </Grid>
                                            <Grid item xs={12}>
                                                <p className={classes.ActusLink} onClick={() => openNewTabWithLink('https://rb.fff.fr')}>Voir toutes les actus &gt;</p>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Container>
                        </div>
                    </main>
                </div>
            </div>
        );
}
