import { Grid } from "@material-ui/core";
import { useStyles } from "../../utils/defaultStyles";
import { openNewTabWithLink } from "../../utils/helperFunc";
import { menu_box } from "../../utils/menuBox";

type habilitationProps = {
    user: any,
    rolesRef: any,
}

export const Habilitation = (props: habilitationProps) => {
    const classes = useStyles();
    if (props.rolesRef && props.user.roles)
        return (
            <>
                <Grid item xs={12}>
                    <Grid container className={classes.vignetteZone}>
                        <Grid item xs={12} className={classes.blocTit}>
                            <p className={classes.vignetteZoneTitle} >{menu_box.habilitations.title} </p>
                            <div className={classes.underLine}> <img src="../static/rectangle.png" alt="underline" /></div>
                        </Grid>
                        <Grid item xs={12}>
                            <Grid container spacing={5}>
                                {
                                    props.rolesRef.map((roleRef: any) => {
                                        return (props.user.roles.map((roleCode: any) => {
                                            if (roleRef.code === roleCode && !roleCode.includes("F2K") && roleRef.url_habilitation) {

                                                return (
                                                    <Grid item xs={4} key={roleRef.code} className={classes.ContentVign}>
                                                        <div className={classes.vignetteBoxBlue} onClick={() => openNewTabWithLink(roleRef.url_habilitation)}>
                                                            <div className={classes.vignetteIcon}>
                                                                <img src={roleRef.icone} width="30" height="30" onError={(event) => (event.target as HTMLElement).style.visibility = 'hidden'} />
                                                                <p className={classes.vignetteTextWhite}> {roleRef.libelle} </p>
                                                            </div>   
                                                        </div>
                                                    </Grid>);
                                            }
                                            else return null;
                                        }));
                                    })
                                }
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </>
        )
    else return null;
}

    