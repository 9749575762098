import { Grid } from "@material-ui/core";
import { useStyles } from "../../utils/defaultStyles";
import { isArrayEmpty, openNewTabWithLink } from "../../utils/helperFunc";
import { menu_box } from "../../utils/menuBox";

type serviceAdministratifsProps = {
    user: any,
    rolesRef: any,
}

export const ServiceAdministratifs = (props: serviceAdministratifsProps) => {
    const classes = useStyles();
    const roles = props.rolesRef ? props.rolesRef.filter((roleRef: any) => roleRef.brique === "SERVICES_ADMINISTRATIFS" && roleRef.url_module && (!roleRef.code.includes("F2K") || ["F2KFAFA_G", "F2KFAFA_C"].indexOf(roleRef.code) !== -1) && (isArrayEmpty(props.user.roles) ? false : props.user.roles.indexOf(roleRef.code) !== -1)) : [];
    if (!isArrayEmpty(roles))
        return (
            <>
                <Grid item xs={12}>
                    <Grid container className={classes.vignetteZone}>
                        <Grid item xs={12} className={classes.blocTit}>
                            <p className={classes.vignetteZoneTitle} >{menu_box.serviceAdmin.title} </p>
                            <div className={classes.underLine}> <img src="../static/rectangle.png" alt="underline" /></div>
                        </Grid>
                        <Grid item xs={12}>
                            <Grid container spacing={5}>
                                {
                                    roles.map((role: any) => {
                                        return (
                                            <Grid item xs={4} key={role.code} className={classes.ContentVign}>
                                                <div className={classes.vignetteBoxBlue} onClick={() => openNewTabWithLink(role.url_module)}>
                                                    <div className={classes.vignetteIcon}>
                                                        <img src={role.icone} width="30" height="30" onError={(event) => (event.target as HTMLElement).style.visibility = 'hidden'} />
                                                        <p className={classes.vignetteTextWhite}> {role.libelle} </p>
                                                    </div>
                                                    
                                                </div>
                                            </Grid>);
                                    })
                                }
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </>
        )
    else return null;
}