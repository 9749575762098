import { Grid } from "@material-ui/core";
import { useStyles } from "../../utils/defaultStyles";
import { isArrayEmpty, openNewTabWithLink } from "../../utils/helperFunc";
import { menu_box } from "../../utils/menuBox";
import {useEffect, useState} from "react";
import axios from "axios";
import {back_end_url} from "../../../app/constants";


export const UniversFFF = () => {
    const classes = useStyles();

    const [univers, setUnivers] = useState(JSON.parse(localStorage.getItem('univers') || '[]'))

    useEffect(() => {
        if (Object.keys(univers).length === 0) {
            axios.get(`${back_end_url}/univers/infos`, { withCredentials: true }).then(
                (response: any) => {
                    localStorage.setItem('univers', JSON.stringify(response.data));
                    setUnivers(response.data)
                },
                () => {}
            )
        }
    }, [univers]);

    if (!isArrayEmpty(univers))
        return (
            <>
                <Grid item xs={12}>
                    <Grid container className={classes.vignetteZone}>
                        <Grid item xs={12} className={classes.blocTit}>
                            <p className={classes.vignetteZoneTitle} >{menu_box.universFFF.title} </p>
                            <div className={classes.underLine}> <img src="../static/rectangle.png" alt="underline" /></div>
                        </Grid>
                        <Grid item xs={12}>
                            <Grid container spacing={5}>
                                {univers.map((item: any, index: number) => {
                                    return (
                                        <Grid item xs={4} key={item.nom} className={classes.ContentVign}>
                                            <div className={classes.vignetteBoxWhite} onClick={() => openNewTabWithLink(item.url_site_web)} >
                                                {/* <div className={classes.vignetteIcon}> <img src={item.icon} width="30" height="30" alt="vignetteIcon" /> </div> */}
                                                <div className={classes.vignetteIcon}> <img src={item.url_logo} width="38" height="49" alt="vignetteIcon" />
                                                    <p className={classes.vignetteTextBlue}> {item.nom}  </p>
                                                </div>
                                               
                                            </div>
                                        </Grid>);
                                })}
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </>
        )
    else return null;
}
