type userInfo = {
    nom: string,
    prenom: string,
    includeNom: boolean,
}

export const NameBox = (props: userInfo) => {
    if (props.includeNom)
        return (<span>{props.prenom + ' ' + props.nom}</span>);
    else if (!props.includeNom)
        return (<span>{props.prenom}</span>);
    else return null;
}