import dayjs from 'dayjs';
import './Actualite.css'
import {goToExternalPage, openNewTabWithLink} from "../utils/helperFunc";
import axios from "axios";
import {back_end_url} from "../../app/constants";
import {useEffect, useState} from "react";


export const Actualite = () => {
    require('dayjs/locale/fr');
    dayjs.locale('fr');
    var customParseFormat = require('dayjs/plugin/customParseFormat');
    dayjs.extend(customParseFormat);
    const FORMAT = "DD/MM/YYYY HH:mm";

    const [actualites, setActualites] = useState(JSON.parse(localStorage.getItem('actualite') || '[]'))

    useEffect(() => {
        if (Object.keys(actualites).length === 0) {
            axios.get(`${back_end_url}/reseaubleu/actualites/5`, { withCredentials: true }).then(
                (response: any) => {
                    localStorage.setItem('actualite', JSON.stringify(response.data));
                    setActualites(response.data)
                },
                () => {}
            )
        }
    }, [actualites]);

    if (Object.keys(actualites).length === 0) return null
    else
        return (<div>
            {actualites.map((news: any) => {
                    return (
                        <div id="actuBox" key={news.actu_detail_date} >
                            <strong id="actuDate">{dayjs(news.actu_detail_date, FORMAT, 'fr').format('DD MMMM YYYY')} </strong>
                            <p id="actuTitle" onClick={() => openNewTabWithLink(news.actu_url)}>{news.actu_detail_title}</p>

                        </div>
                    )
                }
            )}
        </div>);
}
