import { Grid } from "@material-ui/core";
import { useStyles } from "../../utils/defaultStyles";
import { isArrayEmpty, openNewTabWithLink } from "../../utils/helperFunc";
import { menu_box } from "../../utils/menuBox";


export const Foot2000 = (props: any) => {
    const classes = useStyles();
    if (!isArrayEmpty(props.f2000))
        return (
            <>
                <Grid item xs={12}>
                    <Grid container className={classes.vignetteZone}>
                        <Grid item xs={12} className={classes.blocTit}>
                            <p className={classes.vignetteZoneTitle} > {menu_box.f2000.title} </p>
                            <div className={classes.underLine}> <img src="../static/rectangle.png" alt="underline" /></div>
                        </Grid>
                        <Grid item xs={12}>
                            <Grid container spacing={5}>
                                {props.f2000.map((item: any) => {
                                    if (item)
                                        return (
                                            <Grid item xs={4} key={item.nom} className={classes.ContentVign}>
                                                <div className={classes.vignetteBox} onClick={() => openNewTabWithLink(item.url_site_web, item.cookies)}>
                                                    <div className={classes.vignetteIcon}> <img src={item.icon} width="30" height="30" alt="f2000.icon" />
                                                        <p className={classes.vignetteTextWhite}> {item.nom} </p>
                                                    </div>
                                                   
                                                </div>
                                            </Grid>);
                                    else return null;
                                })}
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </>
        )
    else return null;
}