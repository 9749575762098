import axios from "axios";
import Cookies from "js-cookie";
import { useEffect } from "react";
import { isIE } from "react-device-detect";
import { Navigate, useNavigate } from "react-router-dom";
import { back_end_url, cookie_login_name, cookie_muffin_name, FFF_LOGIN, isLocal } from "../../../app/constants";
import { cookieCheck, logOut } from "../../utils/helperFunc";
import { Login } from "./Login";

export const LoginPage = () => {

    if (isIE) {
        return (
            <div>
                <p> Portail Bleu n'est pas compatible avec Internet Explorer.
                    Merci de bien vouloir vous connecter avec l'un des navigateurs suivants : Microsoft Edge, Google Chrome, Mozilla Firefox. </p>
            </div>
        )
    }

    const navigate = useNavigate();
    useEffect(() => {
        cookieCheck(navigate);
    }, [])
    if (isLocal) {
        if (Cookies.get(cookie_login_name)) {
            return <Navigate to="/dashboard" />
        }
        else
            return <Login />
    }
    else if (Cookies.get(cookie_muffin_name)) {
        Promise.all([
            axios.get(`${back_end_url}/session`, { withCredentials: true }),
            axios.get(`${back_end_url}/referentiel/roles`, { withCredentials: true }),
            axios.get(`${back_end_url}/f2000/tuiles`, { withCredentials: true }),
            axios.get(`${back_end_url}/outils`, { withCredentials: true })
        ]).then((response: any) => {
            localStorage.setItem('user', JSON.stringify(response[0].data));
            localStorage.setItem('rolesRef', JSON.stringify(response[1].data));
            localStorage.setItem('f2000', JSON.stringify(response[2].data));
            localStorage.setItem('outils', JSON.stringify(response[3].data));
            navigate('/dashboard', {
                state: {
                    user: response[0].data,
                    rolesRef: response[1].data,
                    f2000: response[2].data,
                    outils: response[3].data
                }
            })
        }).catch((error: any) => {
            if (error.response.status === 401) {
                logOut(navigate);
            }
        });
        return null;
    }
    else return null;
}
