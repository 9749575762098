import { MenuItem, Popover } from "@material-ui/core"
import { useNavigate } from "react-router-dom";
import { useStyles } from "../../utils/defaultStyles"
import { openNewTabWithLink } from "../../utils/helperFunc";

export const MenuDropDown = (props: any) => {
    const classes = useStyles();
    const navigate = useNavigate();

    const open = Boolean(props.anchorEl);
    const id = open ? 'simple-popover' : undefined;

    function goToPage(link: string, user?: any, rolesRef?: any) {
        if (typeof user !== 'undefined' && typeof rolesRef !== 'undefined')
            navigate(link, {
                state: {
                    user: user,
                    rolesRef: rolesRef
                }
            });
        else navigate(link);
    };

    return (
        <Popover className={classes.popOver}
            id={id}
            open={open}
            anchorEl={props.anchorEl}
            onClose={props.handleClose}
            anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'right',
            }}
            transformOrigin={{
                vertical: 'top',
                horizontal: 'right',
            }}>
            {
                props.user.roles.map(
                    (role: any) => {
                        return (props.rolesRef.filter((item: any) => item.code === role && item.brique === 'MENU').map(
                            (item: any) => {
                                return (<MenuItem key={item.code} className={classes.popOverItem}
                                    onClick={() => openNewTabWithLink(item.url_module)}>
                                    <img src="../static/LogoPictosEtFonds/Menu-AdministrationPortailBleu.png" width="30" height="30" alt="vector" />
                                    <p style={{ marginLeft: 10, fontFamily: 'FFF Equipe' }}> {item.libelle} </p>
                                </MenuItem>)
                            }))
                    })
            }
            <MenuItem className={classes.popOverItem}
                onClick={() => goToPage("/gestion", props.user, props.rolesRef)}>
                <img src="../static/LogoPictosEtFonds/Menu-GestionDesHabilitations.png" width="30" height="30" alt="admin" />
                <p style={{ marginLeft: 10, fontFamily: 'FFF Equipe' }}>Gestion des habilitations</p>
            </MenuItem>
        </Popover>)
}

